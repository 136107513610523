<template>
  <div class="section">
    <div class="content">
      <div class="tab">
        <div
          v-for="(item, index) in tabList"
          :key="index"
          :class="tabIndex === index ? 'tab-item tab-active' : 'tab-item'"
          @click="tabClick(index)"
        >
          {{ item }}
        </div>
      </div>
      <div class="list">
        <div class="list-item" v-for="(item, index) in list" :key="index">
          <p @click="handleClick(item)">{{ item.title }}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      tabList: ["党纪教育", "主题教育", "二十大"],
      tabIndex: 0,
      list: [],
      list1: [
        {
          title: "党纪学习教育——中央部署",
          url: "https://www.12371.cn/djxx/zybs/",
          type: 0,
        },
        {
          title: "和行公司各基层党组织召开党纪学习教育启动部署会",
          url: "",
          type: 1,
        },
        {
          title: "和行第一党支部党纪学习教育读书班开班",
          url: "",
          type: 2,
        },
        {
          title: "和瑞公司党支部党纪学习教育读书班开班",
          url: "",
          type: 3,
        },
        {
          title: "和行第一党支部开展党纪学习教育专题党课",
          url: "",
          type: 4,
        },
        {
          title: "和瑞公司党支部开展党纪学习教育专题党课",
          url: "",
          type: 5,
        },
      ],
      list2: [
        {
          title:
            "习近平在安徽考察时强调 发挥多重国家发展战略叠加优势 奋力谱写中国式现代化安徽篇章",
          url: "http://gzw.ah.gov.cn/ztzl/zt/srxxxcgcxjpzsjkcahhzhfzczkzstjzsjythfzzth/xxdt/57548331.html",
        },
        {
          title: "学习贯彻习近平新时代中国特色社会主义思想",
          url: "https://www.12371.cn/special/xxzd/hxnr/",
        },
        {
          title: "安徽先锋网-学习贯彻习近平新时代中国特色社会主义主题教育",
          url: "https://www.ahxf.gov.cn/Zt/shzysx/Index",
        },
        {
          title: "习近平在安徽考察时强调",
          url: "https://tv.cctv.com/2020/08/21/VIDEEqjgK89ztrAfa8DjjUo8200821.shtml",
        },
        {
          title:
            "习近平：健全全面从严治党体系 推动新时代党的建设新的伟大工程向纵深发展",
          url: "https://www.ahxf.gov.cn/Zt/shzysx/Content?Id=1144442&ClassId=6789",
        },
        {
          title: "学习汇编 | 习近平这样阐述“以学铸魂”",
          url: "https://www.ahxf.gov.cn/Zt/shzysx/Content?Id=1144031&ClassId=6799",
        },
        {
          title:
            "江汽集团公司党委组织领导干部赴省党风廉政教育馆开展主题警示教育",
          url: "https://info.jac.com.cn/info/ztjy/20230517/2675.html",
        },
        {
          title:
            "江汽集团公司党委召开学习贯彻习近平新时代中国特色社会主义思想主题教育动员部署会",
          url: "https://info.jac.com.cn/info/ztjy/20230421/2644.html",
        },
      ],
      list3: [
        {
          title: "习近平在学习贯彻二十大精神研讨班上发表重要讲话",
          url: "https://www.12371.cn/2023/02/07/ARTI1675772723487442.shtml",
        },
        {
          title: "和行党总支书记有关二十大的党课开展",
          url: "",
          type: 6,
        },
        {
          title: "江汽集团完成学习贯彻党的二十大精神集中轮训工作",
          url: "https://info.jac.com.cn/info/ershida/20230403/2640.html",
        },
        {
          title:
            "学习党发展全过程人民民主，推进法治中国建设——四论学习贯彻党的二十大精神的二十大精神",
          url: "http://www.news.cn/politics/2022-10/30/c_1129088765.htm",
        },
        {
          title: "肩负起新时代新征程党的使命任务——一论学习贯彻党的二十大精神",
          url: "http://www.news.cn/politics/cpc20/2022-10/25/c_1129079952.htm",
        },
        {
          title: "牢牢把握团结奋斗的时代要求",
          url: "http://www.news.cn/comments/2022-10/22/c_1129075323.htm",
        },
      ],
    };
  },
  created() {
    this.list = this.list1;
  },
  methods: {
    tabClick(index) {
      this.tabIndex = index;
      this.list =
        index === 0 ? this.list1 : index === 1 ? this.list2 : this.list3;
    },
    handleClick(e) {
      let url = "https://www.hexingyueche.com/#";
      if (e.url === "") {
        if (e.type === 1) {
          // this.$router.push({
          //   name: "PartydisciplineStudy",
          // });
          window.open(
            url + "/aboutSelf/companyPartyBuilding/partydisciplineStudy"
          );
        } else if (e.type === 2) {
          // this.$router.push({
          //   name: "PartyOne",
          // });
          window.open(url + "/aboutSelf/companyPartyBuilding/partyOne");
        } else if (e.type === 3) {
          // this.$router.push({
          //   name: "PartyTwo",
          // });
          window.open(url + "/aboutSelf/companyPartyBuilding/partyTwo");
        } else if (e.type === 4) {
          // this.$router.push({
          //   name: "PartyThree",
          // });
          window.open(url + "/aboutSelf/companyPartyBuilding/partyThree");
        } else if (e.type === 5) {
          // this.$router.push({
          //   name: "PartyFour",
          // });
          window.open(url + "/aboutSelf/companyPartyBuilding/partyFour");
        } else if (e.type === 6) {
          // this.$router.push({
          //   name: "Study",
          // });
          window.open(url + "/aboutSelf/companyPartyBuilding/study");
        }
      } else {
        window.open(e.url);
      }
    },
  },
};
</script>

<style lang="less" scoped>
@import url("../../../static/css/home.less");
@import url("../../../static/css/media.less");

.section {
  width: 100%;
  height: 100vh;
  background-image: url("../../assets/partyBuilding.png");
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  overflow-y: auto;
}

.content {
  position: absolute;
  width: 92%;
  background: #fff;
  top: 32%;
  left: 4%;

  .tab {
    display: flex;
    align-items: center;
    padding: 20px;
    box-sizing: border-box;
    border-bottom: solid 1px #cecece;

    &-item {
      width: 120px;
      font-size: 20px;
      cursor: pointer;
    }

    &-active {
      font-weight: 600;
    }
  }

  .list {
    font-size: 16px;
    color: rgba(0, 0, 0, 0.7);

    &-item {
      display: flex;
      align-items: center;
      padding: 20px;
      box-sizing: border-box;
      border-bottom: solid 1px #00000042;

      p {
        cursor: pointer;
      }
    }

    &-item:hover {
      background: rgba(128, 128, 128, 0.075);
    }

    &-item:last-child {
      border-bottom: none;
    }
  }
}
</style>